import { ApplicationController } from "./application_controller.js"

export default class extends ApplicationController {
  static targets = [
    'pickupLocation', 'dropoffLocation', 'selectedCarModel', 'selectedCarTypeId', 'pickupTime',
    'pickupAt', 'slickTimePickerHour', 'slickTimePickerMinutes', 'actionContainer', 'promoCode', 'promoCodeError', 'carTypeRecommendationMsg',
    'travellerPhoneNumber', 'stopLocation', 'paymentSection'
  ]

  initialize() {
    $('.geocode').each( (_, el) => {
      if ($(el).val().length) {
        let locationContainer = $(el).closest('[data-location-container]')

        let lat = $(locationContainer).find('[data-latitude]').val()
        let lng = $(locationContainer).find('[data-longitude]').val()

        this.supportedAirport(el, lat, lng)
        $(el).attr('data-valid-location', true);
      }
    })
  }

  connect() {
    document.addEventListener('submitBookingForm', this.confirm.bind(this));
    document.addEventListener('setupIntentComplete', this.showPendingModal.bind(this));
  }

  disconnect() {
    document.removeEventListener('submitBookingForm', this.confirm.bind(this));
    document.removeEventListener('setupIntentComplete', this.showPendingModal.bind(this));
  }

  confirm() {
    if ($('[data-booking-form]').validate().checkForm()){
      this.showPendingModal()
      // Check if a new payment method needs to be saved (setup intent is true)
      const requiresSetupIntent = this.paymentSectionTarget.getAttribute('data-setup-intent') === 'true';
      if (requiresSetupIntent) {
        const event = new CustomEvent('handleSetupIntent', { bubbles: true, detail: { controller: this } });
        this.element.dispatchEvent(event);
      } else {
        // If no setup intent is required, proceed directly with showing the modal and submitting the form
        this.showPendingModal();
        $('[data-booking-form]').trigger('submit.rails');
      }
    }
  }

  confirmAcknowledge() {
    this.hidePendingModal()
    window.location = window.location.origin + '/bookings'
  }

  beforeRemoveTrip() {
    let modal = $("#booking-pending-modal")
    $(modal).find(".modal-header").html('<h2>Remove Trip</h2>')
    $(modal).find(".modal-body").html('<p>Are you sure you would like to remove the trip from this booking?</p>')
    $(modal).find(".modal-footer").html('<a rel="modal:close" class="close-modal primary-link pull-right me-4" data-bs-dismiss="modal" href="javascript:void(0)">Dismiss</a>\
      <a href="javascript:void(0)" class="button button-fill-violet button-sm" data-cy-confirm-remove-trip data-action="booking#removeTrip booking-summary#update">Remove Trip</a>')


    $("#booking-pending-modal").parent().removeClass('modal-sm')

    $("#booking-pending-modal").modal({ backdrop: 'static', keyboard: false })
    $("#booking-pending-modal").modal('toggle')

    this.elementToRemove = $(event.target).closest('[data-trip-container]')
  }

  removeTrip(){
    $(event.target).addClass('loading')
    let tripIndexToRemove = $(this.elementToRemove).data('trip-index')
    $('[data-pending-modal-header]').empty()
    $('[data-pending-modal-body]').empty()
    $('[data-pending-modal-footer]').empty()

    $("#booking-pending-modal").modal('toggle')

    $(this.elementToRemove).remove()
    $('[data-trip-summary-info][data-trip-index="' + tripIndexToRemove + '"]').parent().remove()

    // Resequence trip data
    $('[data-trip-details]').each( (i, el) => {
      let newTripIndex = i +1

      $(el).data('tripIndex',newTripIndex)
      $(el).attr('data-trip-index',newTripIndex)
      $(el).find('[data-leg-title]').text('Trip ' + newTripIndex)

      $(el).find('[data-trip-index]').each( (_, element) => {
        $(element).data('tripIndex',newTripIndex)
        $(element).attr('data-trip-index',newTripIndex)
      })

      $(el).find('[data-leg]').each( (_, element) => {
        $(element).data('leg',newTripIndex)
        $(element).attr('data-leg',newTripIndex)
      })

      $(el).find('[data-flight]').each( (_, element) => {
        $(element).data('flight',newTripIndex)
        $(element).attr('data-flight',newTripIndex)
      })

      let tripSummaryData = $('[data-trip-summary-info]')[i]

      $(tripSummaryData).data('tripIndex',newTripIndex)
      $(tripSummaryData).attr('data-trip-index', newTripIndex)
      $(tripSummaryData).find('[data-trip-summary-heading]').attr('data-trip-summary-heading', newTripIndex)
      $(tripSummaryData).find('[data-trip-summary-heading]').text('Trip ' + newTripIndex)

      $(tripSummaryData).find('[data-trip-index]').each( (_, element) => {
        $(element).data('tripIndex',newTripIndex)
        $(element).attr('data-trip-index',newTripIndex)
      })

    })

    //Manage remove link visibility
    if($('[data-trip-details]').length == 1) {
      //Hide first trips remove link
      $('[data-target="remove_trip"]:first').addClass('hidden')
    }

    let airportElement = $('[data-trip-details]:last').find('[data-airport]')

    if ($(airportElement).data('type') === 'drop_off_location') {
      let flightNumElement = $('[data-trip-details]:last').find('[data-flight-number]')
      let flightData = this.flightLookup($(flightNumElement))
      // Commented out as we will be coming back to this at a later stage
      // this.showAddRegionalAirportPickupTripButton(airportElement, flightData)
    }
    this.element.dispatchEvent(new CustomEvent('trip-removed', {
      bubbles: true
    }));
  }

  showAddRegionalAirportPickupTripButton(airportElement, flightData) {
    airportElement.closest('.form-group.row')
              .find('.button-regional_pickup')
              .show();
  }

  addTrip() {
    event.preventDefault()
    this.element.dispatchEvent(new CustomEvent('new-trip-added', {
      bubbles: true
    }));
    let trip = this.buildNewTripHtml(event.target.dataset.formPrepend)

    // Clear date value on added trip
    // $(trip).find('.date').val('');
    // $(trip).find('.date_selector').val('');
    // $(trip).find('.slick_time_picker__hour').val('');
    // $(trip).find('.slick_time_picker__minute').val('');

    trip.find('.stop_container').each( (i, el) => {
      $(el).stopSelector()
    })
    trip.find('.geocode').each( (i, el) => {
      $(el).locationSelector()
    })

    trip.find('.fp_datepicker').each( (i, el) => {
      $(el).fpDatePicker()
    })

    trip.find('.fp_timepicker').each( (i, el) => {
      $(el).fpTimePicker()
    })

  }

  addReturnTrip() {
    event.preventDefault()
    let obj = this.buildNewTripHtml(event.target.dataset.formPrepend, 'ReturnTrip')
    if($(obj).prev().get(0).hasAttribute('data-trip-container')) {
      let previousTrip = $(obj).prev();

      let stopValues = [];

      // Set a maximum stops
      let maximumStops = 10;

      for(let i = 1; i <= maximumStops; i++) {
          let stopId = `stop${i}`;
          let stopElement = previousTrip.find(`#${stopId}`);

          if(stopElement.length) {
              let stopValue = stopElement.val();
              let commonParent = stopElement.closest('.row');

              let nearestLatElement = commonParent.find('.lat');
              let nearestLngElement = commonParent.find('.lng');
              let nearestAddressElement = commonParent.find('[data-address-components]');
              let nearestVicinityElement = commonParent.find('#stop_vicinity')
              let latValue = nearestLatElement.length ? nearestLatElement.val() : null;
              let lngValue = nearestLngElement.length ? nearestLngElement.val() : null;
              let addressValue = nearestAddressElement.length ? nearestAddressElement.val() : null;
              let vicinityValue = nearestVicinityElement.length ? nearestVicinityElement.val() : null;

              stopValues.unshift({
                  stop: stopValue,
                  lat: latValue,
                  lng: lngValue,
                  address: addressValue,
                  vicinity: vicinityValue
              });

          } else {
              // If a stop with the expected sequence doesn't exist, exit the loop
              break;
          }
      }


      let pickupLocationDetails = previousTrip.find('[data-pickup-location-details]')
      let pickupLatitude = $(pickupLocationDetails).children('[data-latitude]').val()
      let pickupLongitude = $(pickupLocationDetails).children('[data-longitude]').val()
      let pickupAddress = $(pickupLocationDetails).children('[data-cy-pickup-address-components]').val()
      let pickupVicinity = $(pickupLocationDetails).children('[data-cy-pickup-vicinity]').val()

      let dropoffLocationDetails = previousTrip.find('[data-dropoff-location-details]')
      let dropoffLatitude = $(dropoffLocationDetails).children('[data-latitude]').val()
      let dropoffLongitude = $(dropoffLocationDetails).children('[data-longitude]').val()
      let dropOffAddress = $(dropoffLocationDetails).children('[data-cy-dropoff-address-components]').val()
      let dropOffVicinity = $(dropoffLocationDetails).children('[data-cy-dropoff-vicinity]').val()

      if ($('.flight-info').css('display') === 'block') {
          $(obj).find('.flight-info').css('display', 'block');
      }

      $(obj).find('[data-dropoff-location-details]').children('[data-latitude]').val(pickupLatitude)
      $(obj).find('[data-dropoff-location-details]').children('[data-longitude]').val(pickupLongitude)
      $(obj).find('[data-dropoff-location-details]').children('[data-cy-dropoff-address-components]').val(pickupAddress)
      $(obj).find('[data-dropoff-location-details]').children('[data-cy-dropoff-vicinity]').val(pickupVicinity)
      $(obj).find('[data-pickup-location-details]').children('[data-latitude]').val(dropoffLatitude)
      $(obj).find('[data-pickup-location-details]').children('[data-longitude]').val(dropoffLongitude)
      $(obj).find('[data-pickup-location-details]').children('[data-cy-pickup-address-components]').val(dropOffAddress)
      $(obj).find('[data-pickup-location-details]').children('[data-cy-pickup-vicinity]').val(dropOffVicinity)

      // Clear date value on added trip
      // $(obj).find('.date').val('');
      // $(obj).find('.date_selector').val('');
      // $(obj).find('.slick_time_picker__hour').val('');
      // $(obj).find('.slick_time_picker__minute').val('');

      $(obj).find(".geocode-drop-off").val(previousTrip.find(".geocode-pick-up").val()).trigger("location-changed")
      $(obj).find(".geocode-pick-up").val(previousTrip.find(".geocode-drop-off").val()).trigger("location-changed")
      //$(obj).find(".geocode-stop").val(previousTrip.find(".geocode-stop").val()).trigger("location-changed")

      let newStopContainers = obj.find('.stop_container');

      stopValues.forEach((stopValue, index) => {
          let stopInput = newStopContainers.find(`#stop${index + 1}`);

          if(stopInput.length) {

            stopInput.val(stopValue.stop);
            let commonParent = stopInput.closest('.row');
            let nearestLatElement = commonParent.find('.lat');
            let nearestLngElement = commonParent.find('.lng');
            let nearestAddressElement = commonParent.find('[data-address-components]');
            let nearestVicinityElement = commonParent.find('#stop_vicinity')

            nearestLatElement.val(stopValue.lat);
            nearestLngElement.val(stopValue.lng);
            nearestAddressElement.val(stopValue.address);
            nearestVicinityElement.val(stopValue.vicinity);

            let tripIndex = $(stopInput).data('tripIndex')
            $(newStopContainers).find('[data-stop-destroy-index]').each( (_, element) => {
              $(element).data('stop-trip-index',tripIndex)
              $(element).attr('data-stop-trip-index',tripIndex)
            })

            stopInput.trigger('location-changed');
          }
      });


    }
    // this.populateRegionalPickupTripData()
    $('[data-regional-pickup]').css('display', 'none');
    this.addTripSummary(obj)
    obj.find('.stop_container').each( (i, el) => {
      $(el).stopSelector()
    })
    obj.find('.geocode').each( (i, el) => {
      $(el).locationSelector()
    })

    obj.find('.fp_datepicker').each( (i, el) => {
      $(el).fpDatePicker()
    })

    obj.find('.fp_timepicker').each( (i, el) => {
      $(el).fpTimePicker()
    })

    this.initialize()

    this.element.dispatchEvent(new CustomEvent('return-trip-added', {
      bubbles: true // This makes sure the event bubbles up through the DOM
    }));



    const quoteResetEvent = new CustomEvent('quoteReset', {
      bubbles: true,
    });

    this.element.dispatchEvent(quoteResetEvent);
  }

  addTripSummary(trip) {
    let newTripIndex = $('[data-trip-summary-info]').length + 1

    $.get('/quote_trip_summary',{
      headers: {
          "Content-Type": "application/json",
      },
      trip_leg: newTripIndex
    })
    .then(html => {
      let panelBody = $('<div class="panel-body font-14 border-top">')
      panelBody.append(html)
      $(panelBody).insertAfter($('[data-trip-summary-info]:last').closest('.panel-body'))
      $('[data-trip-summary-heading="1"]').text('Trip 1')
      $('[data-trip-summary-heading="1"]').removeClass('hidden')
      this.fillTripSummary(trip);
    })
  }

  fillTripSummary(trip) {
    let index = $(trip).data('trip-index');
    let summary = $(`.bookings_summary .panel-body:eq(${index - 1})`)
    let pickupAtElement = summary.find('[data-target="booking-summary.pickupAt"]');
    let pickupTimeElement = summary.find('[data-target="booking-summary.pickupTime"]');
    let momentObj = moment($(trip).find(".date").val())
    let formattedDate = momentObj.format('DD MMMM YYYY')
    let formattedTime =  momentObj.format('hh:mm A')

    $(summary).find('.pickup p').html($(trip).find(".geocode-pick-up").val());
    $(summary).find('.dropoff p').html($(trip).find(".geocode-drop-off").val());
    $(summary).find('.dropoff p').html($(trip).find(".geocode-drop-off").val());
    pickupAtElement.html(formattedDate);
    pickupTimeElement.html(formattedTime);
    // Select all geocode-stop (Stops) elements.
    let geocodeStops = $(trip).find(".geocode-stop");

    let stopsContainer = $(summary).find('.stops');

    // Clear previous stops in the summary
    stopsContainer.empty();

    // Append all stops to container
    geocodeStops.slice(1).each(function(index) {
      let stopValue = $(this).val();
      let stopNumber = index + 1;

      // Create wrapper for stop
      let stopDiv = $('<div>');

      let label = $('<label>').text('Stop ' + stopNumber);
      stopDiv.append(label);

      let p = $('<p>').text(stopValue);
      stopDiv.append(p);

      // Append the constructed stopDiv to the stops container
      stopsContainer.append(stopDiv);
    });
  }

  addRegionalPickupTrip() {
    event.preventDefault() // Required to prevent flickering
    const flightData = $(event.currentTarget).attr('data-flight-data')
    let trip = this.buildNewTripHtml(event.target.dataset.formPrepend, 'RegionalTrip')
    this.populateRegionalPickupTripData(flightData, trip)
    $('[data-regional-pickup]').css('display', 'none')
  }

  selectCar() {
    let selectedCarElement = $(event.target)

    // Very strange. For some reason, child elements of .car-selection-button, occassionaly fire this action.
    if (!$(event.target).hasClass('car-selection-button')){
      selectedCarElement = $(event.target).closest('.car-selection-button')
    }

    $(selectedCarElement).siblings('.car-selection-button').removeClass('selected')

    $(selectedCarElement).addClass('selected')

    let selectedModel = $(selectedCarElement).data('model')
    let selectedTypeID = $(selectedCarElement).data('typeId')
    const tripIndex = selectedCarElement[0].dataset.tripIndex

    this.selectedCarModelTargets.forEach((elem, index) => {
      if (elem.dataset.leg == tripIndex){
        elem.value = selectedModel
      }
    })

    this.selectedCarTypeIdTargets.forEach( (elem, index) => {
      if (elem.dataset.leg == tripIndex){
        elem.value = selectedTypeID
      }
    })
  }

  addFlight() {
    let locationContainer = $(event.target).closest('[data-location-container]')
    let latitude = $(locationContainer).find('[data-latitude]').val()
    let longitude = $(locationContainer).find('[data-longitude]').val()
    if (latitude != "" && longitude != ""){
      this.supportedAirport($(event.target), latitude, longitude)
    }
  }

  changePickupTime() {
    let tripDetailsContainer = $(event.target).closest('[data-trip-details]')
    let tripIndex = $(tripDetailsContainer).data('trip-index')
    let recommendedPickupTime = moment(event.target.dataset.recommendedPickupAt, "YYYY-MM-DD HH:mm:ss")
    let meridiem = recommendedPickupTime.format('a')
    const flightNumberElement = $(tripDetailsContainer).find(`[data-flight-number][data-trip-index="${tripIndex}"]`)

    $(tripDetailsContainer).find('.numInput.flatpickr-hour').val(recommendedPickupTime.format('HH')).change()
    $(tripDetailsContainer).find('.numInput.flatpickr-minute').val(recommendedPickupTime.format('mm')).change()

    const fpMeridiemEl = $(tripDetailsContainer).find('.flatpickr-am-pm');
    if(meridiem != fpMeridiemEl.text().toLowerCase()) {
      $(tripDetailsContainer).find('.flatpickr-am-pm').click()
    }

    const notificationBox = $(tripDetailsContainer).find('.notification_box')

    $(flightNumberElement).removeClass('error')
    $(flightNumberElement).data('valid-flight', true)
    $(flightNumberElement).attr('data-valid-flight', true)

    $(notificationBox).fadeOut('slow', function() {
      $(this).hide()
    })

    let customEvent = new CustomEvent('airportAdded', { bubbles: true });
    document.dispatchEvent(customEvent);
  }

  keepPickupTime() {
    let tripDetailsContainer = $(event.target).closest('[data-trip-details]')
    let flightNumberElement = $(tripDetailsContainer).find('input[data-cy-flight-number]')

    $(flightNumberElement).siblings('[data-ignore-arrival-pickup-time-variation]').val(true)
    $(flightNumberElement).data('valid-flight', true)
    $(flightNumberElement).attr('data-valid-flight', true)


    let customEvent = new CustomEvent('airportAdded', { bubbles: true });
    document.dispatchEvent(customEvent);
  }

  requestAssistance() {
    let bookingData = $('[data-booking-form]').serialize()

    $.ajax({
      url: "/bookings/request_assistance",
      type: 'post',
      data: bookingData,
      beforeSend: () => {
        $.rails.CSRFProtection
        $('[data-assisatnce-request]').html('submitting...')
        $('[data-assisatnce-request]').attr('disabled', 'disabled')
      },
      success: (data) => {
        window.location = window.location.origin + '/bookings'
      }
    })
  }

  // TODO: Check if this is still needed.
  populateTravellerDetails() {
    let dataKeys = [ 'id', 'first_name', 'last_name', 'phone', 'email' ]
    $.each( dataKeys, function( index, value ){
      let fieldValue = $(event.target[event.target.selectedIndex]).attr(value)

      if(fieldValue == undefined) {
        return true
      }
      let el = $('#booking_' + value )

      if (value == 'phone') {
        fieldValue = $(el).intlTelInput("setNumber", fieldValue).change()
        $('#booking_internationalized_phone').val($(el).intlTelInput('getNumber'))
      }
      else{
        $(el).val(fieldValue).change()
      }
    })

    let selectedUserId = $(event.target[event.target.selectedIndex]).attr('id')
    if (selectedUserId == undefined) {
      $('[data-location-input-wrapper]').removeClass('ps-0')
      $('[data-fave-location-container]').addClass('hidden')
      return true
    }

    $.ajax({
      url: '/travellers/' + selectedUserId + '/locations',
      type: 'get',
      beforeSend: () => {
        $.rails.CSRFProtection
      },
      success: (data) => {
        $('.fave-location').empty()
        let location_str = '<option value="" disabled selected hidden>Select from favourites</option>'
        let location_data = data

        $.each(data, function() {
          location_str += '<option value="' + this.id + '">' + this.label + '</option>'
        })

        if(location_data.length > 0) {
          $('[data-location-input-wrapper]').addClass('pr-0')
          $('[data-fave-location-container]').removeClass('hidden')
          $('.fave-location').append(location_str)
          $('.fave-location').data("locations", location_data)
        }
        else{
          $('[data-location-input-wrapper]').removeClass('pr-0')
          $('[data-fave-location-container]').addClass('hidden')
        }
      }
    })
  }

  applyPromoCode() {
    let promoCode = $('[data-promotion-code]').val()
    let travellerPhoneNumber = $(this.travellerPhoneNumberTarget).val()

    if (!travellerPhoneNumber) {
      $(this.promoCodeErrorTarget).text('Please fill out the passenger details before applying a promo code.')
    }
    else {
      $.ajax({
        url: "/promo_codes/status",
        type: 'post',
        data: {'code': promoCode, 'phone': travellerPhoneNumber},
        dataType: 'json',
        beforeSend: $.rails.CSRFProtection,
        success: (data) => {
          if (data.status == 'invalid')
          {
            $(this.promoCodeErrorTarget).text(data.msg)
          }
          else {
            $(this.promoCodeTarget).val(promoCode)
          }
        }
      })
    }
  }

  setCarTypeRecommendation() {
    let passengerNumber = Number( $('[data-number-of-passengers]').val() )

    if (($('.childseat_selector__seat_wrapper:visible').length > 0) || (passengerNumber >= 4 && passengerNumber <= 6)) {
      $(this.carTypeRecommendationMsgTarget).show()
    }
    else {
      $(this.carTypeRecommendationMsgTarget).hide()
    }
  }

  private

  supportedAirport(elem, lat, lng) {
    let el = $(elem)
    $.ajax({
      url: "/api/v1/airports/supported",
      type: 'post',
      data: {
        latitude: lat,
        longitude: lng
      },
      beforeSend: $.rails.CSRFProtection,
      success: (data) => {
        if (data.supported){
          el.data('airport', data.airport)
          el.attr('data-airport', data.airport)
          el.data('airport', data.airport)
          el.closest('[data-trip-details]').find('.flight-info').show()
        }
        else{
          el.data('airport', null)
          el.removeAttr('data-airport')

          if (el.closest('[data-trip-details]').find('[data-airport]').length == 0){
            el.closest('[data-trip-details]').find('.flight-info').hide()
          }

          if ($(el).attr('data-type') == 'drop_off_location') {
            $('[data-regional-pickup]').css('display', 'none')
          }
        }

        const customEvent = new CustomEvent('airportAdded', { bubbles: true, detail: { airport: data.airport } });
        document.dispatchEvent(customEvent);
      }
    })
  }

  showPendingModal() {
    let html = '<div class="align-items-center p-5 text-center"><div class="spinner-border spinner-border-md text-secondary me-2" role="status" aria-hidden="true"></div><strong>Placing booking</strong></div>'

    $("#booking-pending-modal").find('.modal-body').html(html);
    $("#booking-pending-modal").modal({ backdrop: 'static', keyboard: false })
    $("#booking-pending-modal").modal('show');
  }

  hidePendingModal(){
    $("#booking-pending-modal").modal('hide')
    $('[data-pending-modal-body]').html('')
  }

  buildNewTripHtml(templateHtml, actionType = 'AddTrip') {
    let newformFieldIndex = $('[data-trip-details]').length
    let newTripIndex = $('[data-trip-details]').length + 1
    let newTripHtml = templateHtml

    newTripHtml = newTripHtml.replace(/new_record/g, newformFieldIndex)
    newTripHtml = newTripHtml.replace(/new_trip_index/g, newTripIndex)

    let obj = $("<div class='panel panel-default panel__trip_details' data-trip-container data-trip-index='" + newTripIndex + "'><div class='panel-body pb-0'>" + newTripHtml + '</div></div>')
    let selectedCarModel = $('.car-selection-button.selected').data('model')
    let selectedCarTypeId = $('.car-selection-button.selected').data('typeId')

    // Populate the new trips charge code with the last charge code if there is one.
    let lastChargeCode = $('[data-charge-code]').filter(function() {return this.value.length !== 0;}).last().val();
    if(lastChargeCode != null){
      obj.find('[data-charge-code]:last').val(lastChargeCode);
    }

    obj.find('.car-kind').val(selectedCarModel)
    obj.find('[data-car-type]').val(selectedCarTypeId)

    // obj.find('.date').each( (i, el) => {
    //   $(el).val('');
    //   $(el).fpDatePicker();
    // })

    obj.find('.geocode').each( (i, el) => {
      $(el).locationSelector()
    })

    let locationData = $('.fave-location').first().data('locations')

    if (actionType === 'ReturnTrip') {
      // Copy stops from the template or create new ones
      let stopContentsTemplate = $('[data-trip-details]').last().find('.stop_container').children().clone();

      obj.find('.stop_container').append(stopContentsTemplate);

      obj.find('.stop_container [name*="trips_attributes"]').each(function() {
        var name = $(this).attr('name');
        var newName = name.replace(/\[trips_attributes\]\[\d+\]/, '[trips_attributes][' + newformFieldIndex + ']');

        $(this).attr('name', newName);
      });

      obj.find('.stop_selector__button-remove').each(function() {
        $(this).attr('data-stop-trip-index', newTripIndex)
      });

      obj.find('.geocode-pick-up').attr('data-valid-location', true)
      obj.find('.geocode-drop-off').attr('data-valid-location', true)
    }

    if (actionType === 'RegionalTrip') {
      // Copy stops from the template or create new ones

      let flightStatsTemplate = $('[data-trip-details]').last().find('.flight-info').children().clone();
      obj.find('.flight-info').addClass('flight_stat-double_spacing')
      obj.find('.flight-info').html('');
      obj.find('.flight-info').append(flightStatsTemplate);
      obj.find('.flight-info').show();
      obj.find('.flight-info').removeClass('flight-info').addClass('new-flight-info');
      obj.find('.new-flight-info').find('.actions').remove();
      obj.find('.new-flight-info').find('.flight_schedule').removeClass('col-xl-6').addClass('col-xl-9');
    }


    obj.insertAfter($('[data-trip-container]:last'))

    $('[data-target="remove_trip"]').each( (_,el) => {
      $(el).removeClass('hidden')
    })

    $('[data-confirm-booking]').remove()
    this.actionContainerTarget.innerHTML = "<button type='button' id='btn-generate_quote' class='button button-full button-fill-violet' data-generate-quote data-action='quote#detailedQuote' } >GENERATE QUOTE</button>"

    $('html, body').animate({
      scrollTop: ($('[data-trip-details]:last').offset().top)
    },500)
    return obj;
  }

  populateRegionalPickupTripData(flightData, trip){
    console.log(flightData);
    let favLocationsHtml = $('[data-fave-location-container]').first().html()
    let locationData = $('.fave-location').first().data('locations')

    let flightDataJson = JSON.parse(flightData)
    let cityName = flightDataJson.arrival_city
    let iataCode = flightDataJson.arrival_iata_code

    let arrivalTimeComponents =  flightDataJson.airport_arrival_time.trim().split(' ').join(':').split(':')
    let arrivalDate = moment(flightDataJson.arrival_date.split('T')[0]).format('DD MMMM YYYY')


    var onElementReady = function(selector) {
      return new Promise((resolve) => {
        let waitForElement = function() {
          if (document.querySelector(selector) != null) {
            resolve($(selector))
          } else {
            window.requestAnimationFrame(waitForElement)
          }
        }
        waitForElement()
      })
    }

    let newTripContainer = $('[data-trip-details]:last')
    $(newTripContainer).find('.date_selector').val(arrivalDate)
    $(':radio[value=' + arrivalTimeComponents[2]+ ']').prop("checked", true)
    $(newTripContainer).find('[data-slick-time-picker-hour]').val(arrivalTimeComponents[0])
    $(newTripContainer).find('[data-slick-time-picker-minute]').val(arrivalTimeComponents[1])
    $(newTripContainer).find('[data-slick-time-picker-hour]').change()

    // Populate pickup location
    let newTripPickupLocation = $(newTripContainer).find('[data-type="pick_up_location"]')

    let location = cityName + ' Airport ('+ iataCode + ')';
    this.geocodeLocation(location, $(newTripPickupLocation), trip);
    this.element.dispatchEvent(new CustomEvent('new-trip-added', {
      bubbles: true
    }));


    onElementReady('.pac-item')
      .then( function(x) {
        $(newTripContainer).find('[data-flight-number]').val(arrivalFlightData.flight_number)
        $(newTripContainer).find('[data-flight-number]').change()
        // Trigger autocomplete to populate google data
        $(newTripContainer).find('[data-type="drop_off_location"]').focus()
      })
  }

  geocodeLocation(location, pickupLocation, trip) {
    // Initialize the geocoder
    var geocoder = new google.maps.Geocoder();

    geocoder.geocode({ 'address': location }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        var firstResult = results[0];
        var formattedAddress = firstResult.formatted_address;
        pickupLocation.val(formattedAddress)
        // SMELL: can we use parent instead of closest? Also, do we even need this?
        pickupLocation.closest('.booking_pickup').find('[data-pickup-location-details]').children('[data-latitude]').val(firstResult.geometry.location.lat())
        pickupLocation.closest('.booking_pickup').find('[data-pickup-location-details]').children('[data-longitude]').val(firstResult.geometry.location.lng())
        pickupLocation.attr('data-valid-location', true);


        this.fillTripSummary(trip);
      } else {
        console.log('Geocode was not successful for the following reason: ' + status);
      }
    });
  }
}
